// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/var/lib/private/gitlab-runner/builds/sxrRz-Rk/0/blog/tech-blog/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("/var/lib/private/gitlab-runner/builds/sxrRz-Rk/0/blog/tech-blog/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-index-js": () => import("/var/lib/private/gitlab-runner/builds/sxrRz-Rk/0/blog/tech-blog/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("/var/lib/private/gitlab-runner/builds/sxrRz-Rk/0/blog/tech-blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/var/lib/private/gitlab-runner/builds/sxrRz-Rk/0/blog/tech-blog/.cache/data.json")

