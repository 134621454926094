import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
  // if (action.type === EXPAND_NAVIGATION) {
  //   return Object.assign({}, state, {
  //     navigationExpanded: true,
  //   });
  // }
  return state;
};

const initialState = { navigationExpanded: false };

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore;
export {};
